import {
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    styled
} from "@mui/material";
import { useAppSelector, useConstructor } from "../../app/hooks";
import Chart from "./Chart";
import { useTranslation } from "react-i18next";
import { store } from "../../app/store";
import {
    currencyChanged as currencyPairChanged,
    getChartCurrencyPairRates,
    intervalChanged,
    sideChanged
} from "./currencyRatesSlice";

export default function CurrencyChart({ elevation = 1 }: { elevation?: number }) {
    const userId = useAppSelector(state => state.login.user?.clientId);

    useConstructor(() => {
        if (userId) {
            store.dispatch(getChartCurrencyPairRates());
        }
    });

    const { t } = useTranslation();

    const currencyPairs = useAppSelector(state => state.currencyRates.options.currencyPairs)
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);

    const sides = useAppSelector(state => state.currencyRates.options.sides)
        .map(item => <MenuItem key={item.id} value={item.id}>{t(item.name)}</MenuItem>);

    const intervals = useAppSelector(state => state.currencyRates.options.intervals)
        .map(item => <MenuItem key={item.id} value={item.id}>{t(item.name)}</MenuItem>);

    const StyledFormControl = styled(FormControl)(({ theme }) => `
        margin: ${theme.spacing(1)};
        min-width: 190px;
    `);

    return (
        <Box>
            <Box component='div' sx={{ display: 'inline' }}>
                <StyledFormControl variant='outlined'>
                    <InputLabel id='currencyPair-label'>{t('currency_pair')}</InputLabel>
                    <Select
                        labelId='currencyPair-label'
                        name='currencyPair'
                        onChange={handleChange}
                        label={t('currency_pair')}
                        value={useAppSelector(state => state.currencyRates.currencyPair)}>
                        {currencyPairs}
                    </Select>
                </StyledFormControl>

                <StyledFormControl variant='outlined'>
                    <InputLabel id='side-label'>{t('side')}</InputLabel>
                    <Select
                        labelId='side-label'
                        name='side'
                        onChange={handleChange}
                        label={t('side')}
                        value={useAppSelector(state => state.currencyRates.side)}>
                        {sides}
                    </Select>
                </StyledFormControl>

                <StyledFormControl variant='outlined'>
                    <InputLabel id='interval-label'>{t('interval')}</InputLabel>
                    <Select
                        labelId='interval-label'
                        name='interval'
                        onChange={handleChange}
                        label={t('interval')}
                        value={useAppSelector(state => state.currencyRates.interval)}>
                        {intervals}
                    </Select>
                </StyledFormControl>
            </Box>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                    width: 610,
                    height: 300
                }
            }}>
                <Paper
                    elevation={elevation}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}
                >
                    <Chart/>
                    <Box style={{
                        position: 'absolute',
                        display: useAppSelector(state => state.currencyRates.loading) ? 'block' : 'none'
                    }}>
                        <CircularProgress/>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

function handleChange(e: SelectChangeEvent) {
    const value = e.target.value;

    switch (e.target.name) {
        case 'side': {
            store.dispatch(sideChanged(value));
            break;
        }
        case 'currencyPair': {
            store.dispatch(currencyPairChanged(value));
            break;
        }
        case 'interval': {
            store.dispatch(intervalChanged(value));
            break;
        }
        default: {
            break;
        }
    }

    store.dispatch(getChartCurrencyPairRates());
}