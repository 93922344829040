// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

export async function client(endpoint, { body, ...customConfig } = {}) {
    const headers = {
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': getCsrfToken()
    };

    const config = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers
        }
    };

    if (body) {
        config.body = JSON.stringify(body);
    }

    let data;

    try {
        const response = await fetch(endpoint, config);
        data = await response.json();

        if (response.ok) {
            return data;
        }
        throw new Error(response.statusText);
    } catch (error) {
        return Promise.reject(error.message ? error.message : data);
    }
}

client.get = (endpoint, customConfig = {}) => {
    return client(endpoint, {
        ...customConfig,
        credentials: 'include'
    });
};

client.post = (endpoint, body, customConfig = {}) => {
    return client(endpoint, {
        ...customConfig,
        credentials: 'include',
        body
    });
};

function getCsrfToken() {
    return document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1');
}
