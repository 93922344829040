export default interface TechMessageState {
    techMessages: TechMessage[];
    shouldShowMessagesBadge: boolean;
}

export interface TechMessage {
    komId: number;
    czasNadania: number;
    tresc: string;
    czasWaznosci: number;
    nadawca: string;
    przeczytany: string
}

export function newTechMessagesState(): TechMessageState {
    return {
        techMessages: [],
        shouldShowMessagesBadge: false
    };
}
