import { Box, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Config from "../../config/Config";

export default function TableRates() {
    const { t } = useTranslation();

    return (
        <Box>
            <Typography variant="body2" sx={{ color: 'text.primary' }}>
                {t('rates_table_info')} <Link href={Config.exchangeRatesTableUrl} target="_blank">{t('here')}</Link>
            </Typography>
        </Box>
    );
}
