import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { client } from '../../api/client'
import { RootState } from '../../app/store';
import { ClientProduct } from '../login/User';
import {
    FormValues,
    newTransactionForm,
    newTransactionFormValues,
    Product,
    TransactionForm,
    ValidationResult
} from './TransactionForm';
import Config from '../../config/Config';
import { fetchCurrencyPairs } from '../currency_rates/currencyRatesSlice';
import { changeSidePointOfView, dateToDateString, dateToTimeString } from "../../services/utils";
import { Transaction } from "../transactions/Transaction";
import { showTransactionsBadge, transactionUpdated } from "../transactions/transactionsListSlice";
import { changeTab } from "../nav/navSlice";
import { NavTab } from "../nav/navTypes";

const initialState = initState();

export const getCurrenciesForType = createAsyncThunk('form/getCurrencies', async (id: number) => {
    return await fetchCurrencyPairs(id.toString());
});

interface ProductCurrency {
    productId: string;
    currency: string;
}

export const checkSubmitAllowed = createAsyncThunk('form/checkSubmitAllowed',
    async (payload: ProductCurrency) => {
        return await client.post(`${Config.dataUrl}/newPricing/submitAllowed`, payload)
    }
);

export const getProductPrecision = createAsyncThunk('form/getProductPrecision',
    async (productId: Product) => {
        return await client.get(`${Config.dataUrl}/newPricing/productPrecision?productId=${productId}`)
    }
);

export const getCurrencyDates = createAsyncThunk('form/getCurrencyDates',
    async (params: ProductCurrency) => {
        return await client.get(
            `${Config.dataUrl}/newPricing/availableCurrencyDates?currency=${params.currency}&productId=${params.productId}`
        );
    }
);

export const getCurrencyDate = createAsyncThunk('form/getCurrencyDate',
    async (params: ProductCurrency) => {
        return await client.get(
            `${Config.dataUrl}/newPricing/currencyDate?clientId=@clientId&currency=${params.currency}&productId=${params.productId}`
        );
    }
);

export const getAccounts = createAsyncThunk('form/getAccounts', async (params: ProductCurrency) => {
    const accountUrl = `${Config.dataUrl}/newPricing/clientAccounts?currency=`;

    const curr1 = params.currency.substring(0, 3);
    const curr2 = params.currency.substring(3, 6);
    const response1 = await client.get(accountUrl + curr1);
    const response2 = await client.get(accountUrl + curr2);

    return [{
        key: curr1,
        value: response1
    }, {
        key: curr2,
        value: response2
    }];
});

export const getMaxValidTimes = createAsyncThunk('form/getMaxValidTimes', async (params: ProductCurrency) => {
    const response = await client.get(
        `${Config.dataUrl}/newPricing/maxValidTimes?clientId=@clientId&productId=${params.productId}`
    );
    return response[params.currency];
});

export const getValidOfferTimes = createAsyncThunk('form/getValidOfferTimes', async (params: ProductCurrency) => {
    return await client.get(
        `${Config.dataUrl}/newPricing/validOfferTimesForProductCurrency?currency=${params.currency}&productId=${params.productId}`
    );
});

export const getMaxDate = createAsyncThunk('form/getMaxDate', async () => {
    return await client.get(`${Config.dataUrl}/parameter/maxdate`);
});

export const getTerm = createAsyncThunk('form/getTerm', async (endDate: Date) => {
    const dateString = dateToDateString(endDate);
    const response = await client.get(`${Config.dataUrl}/newPricing/calculateTerm?endDate=${dateString}`);
    return response.term;
});

export const getTransaction = createAsyncThunk('form/transaction/getCurrentTransaction', async (transactionId: number | string) => {
    return await client.get(
        `${Config.dataUrl}/currentTransactions/getCurrentTransaction?transactionId=${transactionId}`
    );
});

const saveNewTransactionFailed = createAction<string | string[]>('form/transaction/saveNewTransaction/failed');
const saveNewTransactionSuccess = createAction<number>('form/transaction/saveNewTransaction/success');

export function saveNewTransaction() {
    return async (dispatch: any, getState: any) => {
        const state: TransactionForm = getState().form;
        const formValues: FormValues = state.values;

        const payload = {
            ...formValues,
            validTime: dateToTimeString(formValues.validTime),
            endDate: dateToDateString(formValues.endDate),
            strona: changeSidePointOfView(formValues.strona)
        }

        try {
            const response = await client.post(`${Config.dataUrl}/newPricing/submitTransaction`, payload);
            const validationResult: ValidationResult = response.validationResultDTO;

            if (!validationResult.valid) {
                return dispatch(saveNewTransactionFailed(validationResult.errorMessages));
            }

            const transactionId: number = response.transactionId;

            dispatch(saveNewTransactionSuccess(transactionId));
            dispatch(getTransaction(transactionId));
            dispatch(changeTab(NavTab.TRANSACTIONS));
        } catch (error: any) {
            dispatch(saveNewTransactionFailed(error));
        }
    };
}

const acceptFailed = createAction<string | string[]>('form/transaction/acceptTransaction/failed');
const acceptSuccess = createAction('form/transaction/acceptTransaction/success');

export function acceptTransaction() {
    return async (dispatch: any, getState: any) => {
        const transactionId = getState().form.currentTransaction.nrReferencyjny;
        const payload = { transactionId: transactionId };

        try {
            const response: ValidationResult = await client
                .post(`${Config.dataUrl}/currentTransactions/acceptPricing`, payload);

            if (!response.valid) {
                return dispatch(acceptFailed(response.errorMessages));
            }

            dispatch(clearForm());
            dispatch(acceptSuccess());
            dispatch(getTransaction(transactionId));
        } catch (error: any) {
            dispatch(acceptFailed(error));
        }
    };
}

const rejectFailed = createAction<string | string[]>('form/transaction/rejectTransaction/failed');
const rejectSuccess = createAction('form/transaction/rejectTransaction/success');

export function rejectTransaction() {
    return async (dispatch: any, getState: any) => {
        const transactionId = getState().form.currentTransaction.nrReferencyjny;
        const payload = { transactionId: transactionId };

        try {
            const response: ValidationResult = await client
                .post(`${Config.dataUrl}/currentTransactions/rejectPricing`, payload);

            if (!response.valid) {
                return dispatch(rejectFailed(response.errorMessages));
            }

            dispatch(clearForm());
            dispatch(rejectSuccess());
            dispatch(getTransaction(transactionId));
        } catch (error: any) {
            dispatch(rejectFailed(error));
        }
    };
}

const cancelFailed = createAction<string | string[]>('form/transaction/cancelTransaction/failed');
const cancelSuccess = createAction('form/transaction/cancelTransaction/success');

export function cancelTransaction() {
    return async (dispatch: any, getState: any) => {
        const transactionId = getState().form.currentTransaction.nrReferencyjny;
        const payload = { transactionId: transactionId };

        try {
            const response: ValidationResult = await client
                .post(`${Config.dataUrl}/currentTransactions/cancelFxTransaction`, payload);

            if (!response.valid) {
                return dispatch(rejectFailed(response.errorMessages));
            }

            dispatch(clearForm());
            dispatch(cancelSuccess());
            dispatch(getTransaction(transactionId));
        } catch (error: any) {
            dispatch(cancelFailed(error));
        }
    };
}

export const fetchProductTypesForForm = createAction<ClientProduct[]>('form/fetchProductTypes');

export function transactionDataArrived(transaction: Transaction) {
    transaction.strona = changeSidePointOfView(transaction.strona);

    return async (dispatch: any, getState: any) => {
        dispatch(transactionChanged(transaction));
        dispatch(transactionUpdated({
            id: transaction.nrReferencyjny,
            changes: transaction
        }));
        dispatch(showTransactionsBadge());
    }
}

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        productChanged: (state, action) => {
            state.values = newTransactionFormValues();
            state.options.term = '';
            state.values.productId = action.payload;
            state.isError = false;
            state.errorMessages = [];
        },
        currencyChanged: (state, action) => {
            state.values.currency = action.payload;
        },
        valueChanged: (state, action) => {
            const valType: keyof FormValues = action.payload.valType;
            state.values[valType] = action.payload.value;
        },
        transactionChanged: (state, action) => {
            state.currentTransaction = action.payload;
        },
        openDialog: (state, action) => {
            state.dialogOpen = action.payload;
        },
        clearForm: (state) => {
            state.values = newTransactionFormValues();
            state.errorMessages = [];
            state.isError = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrenciesForType.fulfilled, (state, action) => {
                state.options.currencies = action.payload;
            })
            .addCase(checkSubmitAllowed.fulfilled, (state, action) => {
                state.options.submitAllowed = action.payload.allowed;
            })
            .addCase(getProductPrecision.fulfilled, (state, action) => {
                state.options.productPrecision = action.payload;
            })
            .addCase(getCurrencyDates.fulfilled, (state, action) => {
                state.options.currencyDates = action.payload;
            })
            .addCase(getCurrencyDate.fulfilled, (state, action) => {
                state.options.currencyDate = dateToDateString(new Date(action.payload))!;
            })
            .addCase(getAccounts.fulfilled, (state, action) => {
                state.options.account1Curr = action.payload[0].key!;
                state.options.account2Curr = action.payload[1].key;
                state.options.accounts1 = action.payload[0].value;
                state.options.accounts2 = action.payload[1].value;
            })
            .addCase(getMaxValidTimes.fulfilled, (state, action) => {
                state.options.maxValidTimes = action.payload;
            })
            .addCase(getValidOfferTimes.fulfilled, (state, action) => {
                state.options.validOfferTimes = {
                    minTime: new Date(new Date().setHours(action.payload.hoursMin, action.payload.minutesMin)),
                    maxTime: new Date(new Date().setHours(action.payload.hoursMax, action.payload.minutesMax))
                };
            })
            .addCase(getMaxDate.fulfilled, (state, action) => {
                state.options.maxDate = new Date(action.payload);
            })
            .addCase(getTerm.fulfilled, (state, action) => {
                state.options.term = action.payload;
            })
            .addCase(getTransaction.fulfilled, (state, action) => {
                const transaction = action.payload.transaction;
                transaction.strona = changeSidePointOfView(transaction.strona);
                state.currentTransaction = transaction;
                state.serverTs = action.payload.serverTs;
                state.dialogOpen = true;
            })
            .addCase(saveNewTransactionFailed, (state, action) => {
                const errors = action.payload;
                state.isError = true;
                state.errorMessages = typeof errors === 'string' ? [errors] : errors;
                state.dialogOpen = false;
            })
            .addCase(saveNewTransactionSuccess, (state, action) => {
                state.isError = false;
                state.errorMessages = [];
                state.currentTransactionId = action.payload;
                state.dialogOpen = true;
            })
            .addCase(acceptSuccess, (state, action) => {
                state.dialogOpen = false;
            })
            .addCase(acceptFailed, (state, action) => {
                const errors = action.payload;
                state.isError = true;
                state.errorMessages = typeof errors === 'string' ? [errors] : errors;
            })
            .addCase(rejectSuccess, (state, action) => {
                state.dialogOpen = false;
            })
            .addCase(rejectFailed, (state, action) => {
                const errors = action.payload;
                state.isError = true;
                state.errorMessages = typeof errors === 'string' ? [errors] : errors;
            })
            .addCase(cancelSuccess, (state, action) => {
                state.dialogOpen = false;
            })
            .addCase(cancelFailed, (state, action) => {
                const errors = action.payload;
                state.isError = true;
                state.errorMessages = typeof errors === 'string' ? [errors] : errors;
            })
            .addCase(fetchProductTypesForForm, (state, action) => {
                state.options.productTypes = action.payload;
            });
    }
});

function initState() {
    const sides =  [
        { key: 'S', value: 'sell' },
        { key: 'K', value: 'buy' }
    ];

    const state = newTransactionForm();
    state.options.sides = sides;
    return state;
}

export const {
    productChanged,
    valueChanged,
    currencyChanged,
    transactionChanged,
    openDialog,
    clearForm

} = formSlice.actions;

export default formSlice.reducer;

export const isDialogOpen = (state: RootState) => state.form.dialogOpen;
