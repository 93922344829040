import { Box, MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { store } from '../../app/store';
import { useAppSelector } from '../../app/hooks';
import { CommonSelectControl } from '../common/CommonFormControl';
import {
    checkSubmitAllowed,
    currencyChanged,
    getAccounts,
    getCurrenciesForType,
    getCurrencyDate,
    getCurrencyDates,
    getMaxDate,
    getMaxValidTimes,
    getProductPrecision,
    getValidOfferTimes,
    saveNewTransaction,
    productChanged,
    valueChanged,
} from './transactionFormSlice';
import { FormValues, Product, ValueChange } from "./TransactionForm";
import FxSpotInputSet from "./FxSpotInputSet";
import FxOrderInputSet from "./FxOrderInputSet";
import DepozytNegInputSet from "./DepozytNegInputSet";
import { ChangeEvent } from "react";

export default function Form() {
    const products = useAppSelector(state => state.form.options.productTypes)
        .map(item => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>);
    const errorMessages = useAppSelector(state => state.form.errorMessages)
        .map(((message, index) => <Typography key={index} sx={{ color: 'red'}}>{message}</Typography>));

    const isError = useAppSelector(state => state.form.isError);
    const isDialogOpen = useAppSelector(state => state.form.dialogOpen);
    const productId = useAppSelector(state => state.form.values.productId);

    return (
        <Box>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `'type . . . . . .'`,
                marginBottom: '8px'
            }}>
                <CommonSelectControl
                    name='type'
                    items={products}
                    callback={handleTypeChangeEvent}
                    value={productId}
                />
            </Box>
            {productId === Product.FX_SPOT &&
                <FxSpotInputSet />
            }
            {productId === Product.FX_ORDER &&
                <FxOrderInputSet />
            }
            {productId === Product.DEPOZYT_NEG &&
                <DepozytNegInputSet />
            }
            {isError && !isDialogOpen &&
                errorMessages
            }
        </Box>
    );
}

function handleTypeChangeEvent(e: SelectChangeEvent) {
    handleProductChange(e.target.value as Product);
}

export function handleProductChange(product: Product) {
    store.dispatch(productChanged(product));
    store.dispatch(getCurrenciesForType(Number(product)));

    if (product === Product.FX_ORDER) {
        store.dispatch(getProductPrecision(product));
    }

    if (product === Product.DEPOZYT_NEG) {
        store.dispatch(getMaxDate());
    }
}

export function handleCurrencyChangeEvent(e: SelectChangeEvent) {
    handleCurrencyChange(e.target.value);
}

export function handleCurrencyChange(currency: string) {
    const productId = store.getState().form.values.productId;

    store.dispatch(currencyChanged(currency));
    store.dispatch(getCurrencyDates({ currency, productId }));
    store.dispatch(getAccounts({ currency, productId }));
    store.dispatch(checkSubmitAllowed({ currency, productId }));

    if (productId === Product.FX_ORDER) {
        store.dispatch(getMaxValidTimes({ currency, productId }));
        store.dispatch(getValidOfferTimes({ currency, productId }));
    }

    if (productId === Product.DEPOZYT_NEG) {
        store.dispatch(getCurrencyDate({ currency, productId }));
    }
}

export function handleChangeEvent(e: SelectChangeEvent) {
    handleValueChange({ valType: e.target.name as keyof FormValues, value: e.target.value });
}

export function handleValueChange(change: ValueChange) {
    store.dispatch(valueChanged(change));
}

export function handleTextFieldChange(e: ChangeEvent<HTMLInputElement>) {
    store.dispatch(valueChanged({ valType: e.target.name, value: e.target.value }));
}

export function handlePickerChange(valType: string, value: Date) {
    store.dispatch(valueChanged({ valType, value }));
}

export function handleSubmit() {
    store.dispatch(saveNewTransaction());
}

export function handleNumericValueChange(e: ChangeEvent<HTMLInputElement>) {
    store.dispatch(valueChanged({ valType: e.target.name, value: e.target.value.replace(',', '.') }));
}

export function handleAmountChange(e: ChangeEvent<HTMLInputElement>) {
    if (shouldUpdateAmount(e.target.value)) {
        handleNumericValueChange(e);
    }
}

function shouldUpdateAmount(amount: string) {
    return /^\d*([.,]\d{0,2})?$/
        .test(amount);
}