import ReactDOM from 'react-dom';
import './index.css';
import './i18n/i18n';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { StrictMode } from 'react';
import GlobalErrorBoundary from "./error/GlobalErrorBoundary";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import { BrowserRouter } from "react-router-dom";

const element = (
    <StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <GlobalErrorBoundary>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </GlobalErrorBoundary>
            </ThemeProvider>
        </Provider>
    </StrictMode>
);

ReactDOM.render(element, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
