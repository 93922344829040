import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./ErrorPage";
import { useAppDispatch } from "../app/hooks";
import { logoutUser } from "../features/login/loginSlice";
import { ReactNode } from "react";

export default function GlobalErrorBoundary(props: { children: ReactNode }) {
    const dispatch = useAppDispatch();

    return (
        <ErrorBoundary
            FallbackComponent={ErrorPage}
            onError={(error, errorInfo) => {
                void dispatch(logoutUser());
                console.error(error);
                console.error(errorInfo);
            }}
            onReset={() => {
                window.location.reload();
            }}
        >
            {props.children}
        </ErrorBoundary>
    );
}