import { Box } from '@mui/material';
import RatesTable from './RatesTable';
import React from "react";
import CurrencyChart from "./CurrencyChart";

function CurrencyRates() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
            <RatesTable />
            <CurrencyChart />
        </Box>
    );
}

export default CurrencyRates;
