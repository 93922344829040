import './App.css';
import Home from './features/home/home';
import Login from './features/login/Login';
import SockJsClient from 'react-stomp';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { logoutUser, selectUser } from './features/login/loginSlice';
import { RateChangeDto } from './features/currency_rates/RateChangeDto';
import { chartDataArrived, currentRateDataArrived } from './features/currency_rates/currencyRatesSlice';
import { Transaction } from './features/transactions/Transaction';
import Config from './config/Config';
import { CurrentCurrencyRate } from './features/currency_rates/CurrencyRatesState';
import { ActionCreator } from '@reduxjs/toolkit';
import TechMessage from './features/tech_messages/TechMessage';
import { techMessageArrived } from './features/tech_messages/techMessagesSlice';
import SsoLogin from "./features/login/SsoLogin";
import { transactionDataArrived } from "./features/exchange/transactionFormSlice";
import { useIdleTimer } from "react-idle-timer";

let clientRef: any = null;

function App() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const location = useLocation();

    const idleTimer = useIdleTimer({
        onIdle: () => dispatch(logoutUser()),
        timeout: Config.logoutTimeout,
    })

    if (location.pathname === '/ssologin') {
        return <SsoLogin />;
    }

    if (!user) {
        return <Login/>;
    }

    return (
        <>
            <SockJsClient
                url={Config.websocketUrl}
                topics={[
                    '/user/queue/CHART_CURRENCY_RATE',
                    '/user/queue/TRANSACTION',
                    '/user/queue/NEW_TECH_MESSAGE',
                    '/user/queue/CURRENCY_RATE_CHANGE'
                ]}
                onMessage={onMessageReceive}
                ref={(client: any) => {
                    clientRef = client;
                }}
                debug={false}
            />
            <Routes>
                <Route path='/' element={<Home onLogoutClick={handleLogoutClick}/>} />
                <Route path='/login' element={<Login/>} />
            </Routes>
        </>
    );

    function handleLogoutClick() {
        clientRef.disconnect();
        void dispatch(logoutUser());
    }

    function onMessageReceive(message: string | object, topic: string) {
        switch (topic) {
            case '/user/queue/CHART_CURRENCY_RATE':
                chartCurrencyRateHandler(message as string | RateChangeDto);
                break;
            case '/user/queue/TRANSACTION':
                newTransactionHandler(message as string | Transaction);
                break;
            case '/user/queue/CURRENCY_RATE_CHANGE':
                currencyRateChangeHandler(message as string | CurrentCurrencyRate);
                break;
            case '/user/queue/NEW_TECH_MESSAGE':
                newTechMessageHandler(message as string | TechMessage);
                break;
        }
    }

    function chartCurrencyRateHandler(data: string | RateChangeDto) {
        messageHandler(data, chartDataArrived);
    }

    function newTransactionHandler(data: string | Transaction) {
        messageHandler(data, transactionDataArrived);
    }

    function currencyRateChangeHandler(data: string | CurrentCurrencyRate) {
        messageHandler(data, currentRateDataArrived);
    }

    function newTechMessageHandler(data: string | TechMessage) {
        messageHandler(data, techMessageArrived);
    }

    function messageHandler<MessageType>(message: string | MessageType, actionCreator: ActionCreator<any>) {
        let data: MessageType;
        if (typeof message === 'string') {
            data = JSON.parse(message);
        } else {
            data = message;
        }
        dispatch(actionCreator(data));
    }
}

export default App;
