import './header.css';
import bpsLogo from '../../img/logo.jpg';
import defaultBsLogo from '../../img/logo-group.png';
import { Box, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExitToApp } from '@mui/icons-material/';
import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../login/loginSlice';
import { useTranslation } from 'react-i18next';
import { HomeProps } from '../home/home';
import { isBsClient } from "../../services/utils";

const StyledPaper = styled(Paper)(({ theme }) => `
    display: inline-flex;
    padding: ${theme.spacing(3)};
    marginRight: ${theme.spacing(1)};
    textAlign: center;
    color: ${theme.palette.text.secondary};
`);

function Header({ onLogoutClick }: Readonly<HomeProps>) {
    const user = useAppSelector(selectUser);
    const { t } = useTranslation();

    return (
        <Box sx={{ gridRow: '1 / 3' }}>
            <Box sx={{
                display: 'grid',
                gap: 2
            }}>
                <Box sx={{
                    display: 'grid',
                    height: '80%',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gridColumn: '1 / 2',
                }}>
                    {isBsClient(user?.clientId) && user?.bsLogo &&
                        <img src={`data:image/png;base64, ${user.bsLogo}`} alt='logo'/>
                    }
                    {isBsClient(user?.clientId) && !user?.bsLogo &&
                        <img src={defaultBsLogo} alt='logo'/>
                    }
                    {!isBsClient(user?.clientId) &&
                        <img src={bpsLogo} alt='logo'/>
                    }

                </Box>
                <Box sx={{
                    display: 'inline-flex',
                    alignItems: 'stretch',
                    alignContent: 'stretch',
                    justifyContent: 'flex-end',
                    gridColumn: '4 / 8',
                    gridRow: 'span 1'
                }}>
                    {user &&
                        <StyledPaper sx={{
                            mr: '5px',
                            gridColumn: '1',
                            lineHeight: '1.85'
                        }}>
                            {user.firstName} {user.lastName} ({user.clientName})
                        </StyledPaper>
                    }
                    <Button
                        sx={{ gridColumn: '2' }}
                        size='small'
                        name='Logout'
                        onClick={onLogoutClick}
                        startIcon={<ExitToApp/>}
                        variant='outlined'
                        color='secondary'
                    >
                        {t('logout')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default Header;
