import { getCurrentUser, selectUser } from './loginSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Navigate } from "react-router-dom";

export default function SsoLogin() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);

    if (!user) {
        void dispatch(getCurrentUser());
    }

    return <Navigate replace to='/' />;
}
