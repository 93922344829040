import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { RootState } from '../../app/store';
import Config from '../../config/Config';
import { CurrencyRates, CurrentCurrencyRate, newCurrencyRates, Rate } from './CurrencyRatesState';
import { RateChangeDto } from './RateChangeDto';
import { Product } from "../exchange/TransactionForm";

const initialState: CurrencyRates = initState();

export async function fetchCurrencyPairs(productId: string) {
    return await client.get(`${Config.dataUrl}/dictionary/currencies/${productId}/false`);
}

export const getCurrencyPairs = createAsyncThunk('currencyRates/pairs', async (productId: string) => {
    return await fetchCurrencyPairs(productId);
});

export const getChartCurrencyPairRates = createAsyncThunk<any, void, { state: RootState }>(
    'currencyRates/rates',
    async (_, { getState }) => {
        let { side, interval, currencyPair } = getState().currencyRates;
        if (!currencyPair) {
            const currencyPairs = await fetchCurrencyPairs(Product.FX_SPOT);
            currencyPair = currencyPairs[0];
        }
        return await client.get(`${Config.dataUrl}/chart/${side}_ratesfor_${interval}/@clientId/${currencyPair}`);
    }
);

export const getCurrentCurrencyRates = createAsyncThunk('currencyRates/current_rates', async () => {
    return await client.get(`${Config.dataUrl}/currencyrates/listForClient/@clientId`);
})

export function getSubscribeCurrencyRatesForAmount(waluta: string, kwota: number) {
    return client.get(`${Config.dataUrl}/currencyrates/subscribe?waluta=${waluta}&kwota=${kwota}`);
}

const currencyRatesSlice = createSlice({
    name: 'currencyRates',
    initialState,
    reducers: {
        currencyChanged: (state, action) => {
            state.currencyPair = action.payload;
        },
        sideChanged: (state, action) => {
            state.side = action.payload;
        },
        amountChanged: (state, action) => {
            state.currencyAmounts[action.payload[0]] = action.payload[1];
        },
        prevAmountChanged: (state, action) => {
            state.prevCurrencyAmounts[action.payload[0]] = action.payload[1];
        },
        amountsChanged: (state, action) => {
            state.currencyAmounts = action.payload;
        },
        intervalChanged: (state, action) => {
            state.interval = action.payload;
        },
        chartDataArrived: (state, action) => {
            const rateChangeDto: RateChangeDto = action.payload;
            if (
                rateChangeDto.waluta.toUpperCase() === state.currencyPair.toUpperCase() &&
                rateChangeDto.strona.toUpperCase() === state.side.toUpperCase() &&
                rateChangeDto.typ.toUpperCase() === state.interval.toUpperCase()
            ) {
                const rate: Rate = rateChangeDto.rateDTO;
                let label = getLabel(rate);
                state.chartData.labels.push(label);
                state.chartData.datasets[0]?.data?.push(rate.kurs);
            }
        },
        currentRateDataArrived: (state, action) => {
            const rateData: CurrentCurrencyRate = action.payload;
            state.currentCurrencyRates = state.currentCurrencyRates.map(rate =>
                rate.waluta === rateData.waluta ? { ...rate, ...rateData } : rate
            );
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrencyPairs.fulfilled, (state, action) => {
                state.options.currencyPairs = action.payload;
                state.currencyPair = action.payload[0];
            })
            .addCase(getChartCurrencyPairRates.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getChartCurrencyPairRates.fulfilled, (state, action) => {
                state.loading = false;
                state.chartData.labels = getLabels(action.payload);
                state.chartData.datasets = [createDataSet(action.payload)];
            })
            .addCase(getCurrentCurrencyRates.fulfilled, (state, action) => {
                state.currentCurrencyRates = action.payload;
                if (state.currencyAmounts.length === 0) {
                    state.currencyAmounts = action.payload.map((rate: CurrentCurrencyRate) => rate.kwotaBazowa);
                    state.prevCurrencyAmounts = state.currencyAmounts;
                }
            });
    }
});

function createDataSet(rates: Rate[]) {
    return {
        data: getDataSetValues(rates)
    };
}

function getDataSetValues(rates: Rate[]) {
    return rates.map(item => item.kurs);
}

function getLabels(rates: Rate[]) {
    return rates.map(rate => getLabel(rate));
}

function getLabel(rate: Rate) {
    return new Date(Number(rate.czas));
}

function initState() {
    const sides = [
        { id: 's', name: 'buy_rate' },
        { id: 'k', name: 'sell_rate' },
        { id: 'r', name: 'average_rate' }
    ];

    const intervals = [
        { id: 'd', name: 'day' },
        { id: 'w', name: 'week' }
    ];

    const state = newCurrencyRates();
    state.options.sides = sides;
    state.options.intervals = intervals;
    state.side = sides[2].id;
    state.interval = intervals[0].id;
    return state;
}

export const {
    currencyChanged,
    sideChanged,
    amountChanged,
    amountsChanged,
    prevAmountChanged,
    chartDataArrived,
    intervalChanged,
    currentRateDataArrived
} = currencyRatesSlice.actions;

export default currencyRatesSlice.reducer;
