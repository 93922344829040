import { Box, Typography } from '@mui/material';

function Footer() {
    return (
        <Box>
            <Typography variant='body2' sx={{
                textAlign: 'center',
                color: 'white',
            }}>
                &copy; {new Date().getFullYear()} Grupa BPS
            </Typography>
        </Box>
    );
}

export default Footer;
