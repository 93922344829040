import { TableType } from "../transactions/Transaction";
import { useAppSelector } from "../../app/hooks";
import { selectFxOrderTransactions } from "./startSlice";
import DoneTransactionsTable from "./DoneTransactionsTable";


export default function FxOrderTransactionsTable() {
    const fxOrderTransactions = useAppSelector(selectFxOrderTransactions);

    return <DoneTransactionsTable transactions={fxOrderTransactions} type={TableType.FX_ORDER} />
}