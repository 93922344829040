import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';
import { useAppSelector } from '../../app/hooks';

const DAY_INTERVAL = 'd';
const WEEK_INTERVAL = 'w';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    scales: {
        x: {
            type: 'time',
            time: {}
        }
    },
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: (tooltipItem: any) => tooltipItem.raw.toFixed(4)
            }
        }
    },
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
    elements: {
        point:{
            radius: 0,
            hitRadius: 10
        },
        line: {
            tension: 0.1
        }
    }
};

function Chart() {
    const interval = useAppSelector(state => state.currencyRates.interval)

    if (interval === DAY_INTERVAL) {
        options.scales.x.time = {
            unit: 'minute',
            stepSize: 5,
            displayFormats: {
                minute: 'HH:mm'
            },
            tooltipFormat: 'HH:mm'
        }
    } else if (interval === WEEK_INTERVAL) {
        options.scales.x.time = {
            unit: 'day',
            stepSize: 1,
            displayFormats: {
                day: 'dd/MM'
            },
            tooltipFormat: 'dd/MM HH:mm'
        }
    }

    // @ts-ignore
    return <Line options={options} data={useAppSelector(state => state.currencyRates.chartData)} />;
}

export default Chart;
