import { Box, Button, Link, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CurrencyChart from "../currency_rates/CurrencyChart";
import { styled } from "@mui/material/styles";
import TodaysTransactionsTable from "./TodaysTransactionsTable";
import FxOrderTransactionsTable from "./FxOrderTransactionsTable";
import { store } from "../../app/store";
import { changeTab } from "../nav/navSlice";
import { NavTab } from "../nav/navTypes";
import { productChanged } from "../exchange/transactionFormSlice";
import { Product } from "../exchange/TransactionForm";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUser } from "../login/loginSlice";
import { downloadFile } from "./startSlice";
import Config from "../../config/Config";
import { isBsClient } from "../../services/utils";

const Tiling = styled(Box)`
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
    grid-template-rows: auto;
    grid-template-areas:
        "chart transactions"
        "login fxorder"
        "regulations ."
`;

const Tile = styled(Paper)`
    padding: 0.5rem 1rem 1rem 1rem;
`;

const TileHeader = styled(Box)`
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LoginInfoBox = styled(Paper)`
    display: flex;
    gap: 0.75rem;
    align-items: center;
    padding: 0.75rem 2rem 0.75rem 1.6rem;
`;

const RegulationsBox = styled(Paper)`
    display: flex;
    gap: 2rem;
    align-items: center;
    padding: 1rem 2rem;
`;

const FileLink = styled(Link)`
    &:hover {
        cursor: pointer;
    }
`;

export default function Start() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);

    return (
        <Tiling>
            <Tile sx={{ gridArea: 'chart'}}>
                <TileHeader>
                    <Typography variant='h6'>{t('start_currency_rates_chart')}</Typography>
                </TileHeader>
                <CurrencyChart />
            </Tile>
            <Tile sx={{ gridArea: 'transactions'}}>
                <TileHeader>
                    <Box>
                        <Typography variant='h6'>{t('start_transactions')}</Typography>
                        <Typography variant='body2'>{t('start_transactions_description')}</Typography>
                    </Box>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleNewTransaction}
                    >
                        {t('start_new_transaction')}
                    </Button>
                </TileHeader>
                <TodaysTransactionsTable />
            </Tile>
            <Tile sx={{ gridArea: 'login'}}>
                <TileHeader>
                    <Box>
                        <Typography variant='h6'>{t('start_login_info')}</Typography>
                        <Typography variant='body2'>{t('start_login_info_description')}</Typography>
                    </Box>
                </TileHeader>
                <Box sx={{ display: 'flex' }}>
                    <LoginInfoBox>
                        <CheckCircleIcon fontSize='large' color='primary' />
                        <Box>
                            <Typography variant='body2'>{t('start_last_correct_login')}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>{user?.lastCorrectLogin}</Typography>
                        </Box>
                    </LoginInfoBox>
                    <LoginInfoBox>
                        <CancelIcon fontSize='large' color='error' />
                        <Box>
                            <Typography variant='body2'>{t('start_last_bad_login')}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>{user?.lastBadLogin}</Typography>
                        </Box>
                    </LoginInfoBox>
                </Box>
            </Tile>
            <Tile sx={{ gridArea: 'fxorder'}}>
                <TileHeader>
                    <Box>
                        <Typography variant='h6' sx={{ textTransform: 'uppercase' }}>{t('start_fx_order')}</Typography>
                        <Typography variant='body2'>{t('start_fx_order_description')}</Typography>
                    </Box>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleNewFxOrderTransaction}
                    >
                        {t('start_new_transaction')}
                    </Button>
                </TileHeader>
                <FxOrderTransactionsTable />
            </Tile>
            {!isBsClient(user?.clientId) &&
                <Tile sx={{ gridArea: 'regulations'}}>
                    <TileHeader>
                        <Typography variant='h6'>{t('start_bank_regulations')}</Typography>
                    </TileHeader>
                    <Box sx={{ display: 'flex', gap: '0.75rem' }}>
                        <RegulationsBox>
                            <FileLink variant='body1' onClick={() =>
                                dispatch(downloadFile(Config.regulationsFileUrl))
                            }>
                                {t('start_regulations')}
                            </FileLink>
                            <FileLink variant='body1' onClick={() =>
                                dispatch(downloadFile(Config.depositFileUrl))
                            }>
                                {t('start_deposit')}
                            </FileLink>
                            <FileLink variant='body1' sx={{ textTransform: 'uppercase' }} onClick={() =>
                                dispatch(downloadFile(Config.fxSpotFileUrl))
                            }>
                                {t('start_fx_spot')}
                            </FileLink>
                            <FileLink variant='body1' sx={{ textTransform: 'uppercase' }} onClick={() =>
                                dispatch(downloadFile(Config.fxOrderFileUrl))
                            }>
                                {t('start_fx_order')}
                            </FileLink>
                        </RegulationsBox>
                    </Box>
                </Tile>
            }
        </Tiling>
    )
}

function handleNewTransaction() {
    store.dispatch(productChanged(''));
    store.dispatch(changeTab(NavTab.EXCHANGE));
}

function handleNewFxOrderTransaction() {
    store.dispatch(productChanged(Product.FX_ORDER));
    store.dispatch(changeTab(NavTab.EXCHANGE));
}
