import { Button, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFormControl = styled(FormControl)(({ theme }) => `
    margin: ${theme.spacing(1)};
    min-width: 200px;
`);

export const StyledButton = styled(Button)(({ theme }) => `
    margin: ${theme.spacing(3, 0, 2)};
`);