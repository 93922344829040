import { Action, AnyAction, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import transactionReducer from '../features/transactions/transactionsListSlice';
import navReducer from '../features/nav/navSlice';
import formReducer from '../features/exchange/transactionFormSlice';
import loginReducer, {clearStore} from '../features/login/loginSlice';
import historyReducer from '../features/history/historySlice';
import thunk from 'redux-thunk';
import currencyRatesReducer from '../features/currency_rates/currencyRatesSlice';
import techMessagesReducer from '../features/tech_messages/techMessagesSlice';
import startReducer from '../features/start/startSlice';

function loadState() {
    try {
        const serializedState = sessionStorage.getItem('app_state');
        if (!serializedState) return undefined;
        return JSON.parse(serializedState);
    } catch (error) {
        return undefined;
    }
}

const reducers = combineReducers({
    transactions: transactionReducer,
    nav: navReducer,
    form: formReducer,
    login: loginReducer,
    currencyRates: currencyRatesReducer,
    history: historyReducer,
    techMessages: techMessagesReducer,
    start: startReducer
});

const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === clearStore.type) {
        state = {};
    }
    return reducers(state, action);
}

export const store = configureStore({
    reducer: rootReducer,
    preloadedState: loadState(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(thunk)
});

store.subscribe(() => {
    try {
        const serializedState = JSON.stringify(store.getState());
        sessionStorage.setItem('app_state', serializedState);
    } catch (error) {
        console.error(error);
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
