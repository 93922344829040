import { ClientProduct } from '../login/User';

export interface FilterForm {
    values: Values;
    options: Options;
}

export interface Values {
    currency: string;
    status: string;
    product: string;
    amount: string;
    dateFrom: string | null;
    dateTo: string | null;
}

export interface Options {
    currencies: string[];
    statuses: Status[];
    products: ClientProduct[];
}

export interface Status {
    id: string;
    description: string;
}

export function newFilterForm(): FilterForm {
    return {
        values: newFilterValues(),
        options: {
            currencies: [],
            statuses: [],
            products: []
        }
    };
}

export function newFilterValues(): Values {
    return {
        currency: '',
        status: '',
        product: '',
        amount: '',
        dateFrom: null,
        dateTo: null
    };
}
