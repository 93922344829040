import { selectHistory } from './historySlice';
import { useAppSelector } from '../../app/hooks';
import TransactionsTable from '../transactions/transactionsList';
import Filter from './filter';
import { Box } from '@mui/material';
import { TableType } from "../transactions/Transaction";

export default function BasicTable() {
    const history = useAppSelector(selectHistory);

    return (
        <Box>
            <Filter/>
            <TransactionsTable transactions={history} type={TableType.HISTORY} />
        </Box>
    );
}
