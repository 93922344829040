import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { client } from '../../api/client'
import { RootState } from '../../app/store';
import Config from '../../config/Config';
import { Pagination } from '../tech_messages/techMessagesSlice';
import { DoneTransaction } from "./startTypes";
import { mapDoneTransactions } from "../../services/utils";

const todaysTransactionsAdapter = createEntityAdapter<DoneTransaction>({
    selectId: (transaction) => transaction.refNr
});

const fxOrderTransactionsAdapter = createEntityAdapter<DoneTransaction>({
    selectId: (transaction) => transaction.refNr
});

const initialState = {
    todaysTransactions: todaysTransactionsAdapter.getInitialState({
        status: '',
        entities: {},
        sort: ''
    }),
    fxOrderTransactions: fxOrderTransactionsAdapter.getInitialState({
        status: '',
        entities: {},
        sort: ''
    })
}

const defaultPagination = {
    pageNo: 1,
    pageSize: 5
};

export const fetchTodaysTransactions = createAsyncThunk('start/fetchDoneTransactions',
    async (params: Pagination = defaultPagination) => {
        const response = await client.get(
            `${Config.dataUrl}/transactions/listCurrentDoneUserTransactions?` +
            `pageNo=${params.pageNo}&pageSize=${params.pageSize}`
        );
        return response.transactions;
    }
);

export const fetchFxOrderTransactions = createAsyncThunk('start/fetchFxOrderTransactions',
    async (params: Pagination = defaultPagination) => {
        const response = await client.get(
            `${Config.dataUrl}/transactions/listUserOrderFx?` +
            `pageNo=${params.pageNo}&pageSize=${params.pageSize}`
        );
        return response.transactions;
    }
);

export function downloadFile(url: string) {
    return async (dispatch: any, getState: any) => window.open(url);
}

const startSlice = createSlice({
    name: 'start',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTodaysTransactions.pending, (state, action) => {
                state.todaysTransactions.status = 'loading';
            })
            .addCase(fetchTodaysTransactions.fulfilled, (state, action) => {
                todaysTransactionsAdapter.setAll(state.todaysTransactions, mapDoneTransactions(action.payload));
                state.todaysTransactions.status = 'idle';
            })
            .addCase(fetchFxOrderTransactions.pending, (state, action) => {
                state.fxOrderTransactions.status = 'loading';
            })
            .addCase(fetchFxOrderTransactions.fulfilled, (state, action) => {
                fxOrderTransactionsAdapter.setAll(state.fxOrderTransactions, mapDoneTransactions(action.payload));
                state.fxOrderTransactions.status = 'idle';
            });
    }
});

export default startSlice.reducer;

export const {
    selectAll: selectTodaysTransactions
} = todaysTransactionsAdapter.getSelectors((state: RootState) => state.start.todaysTransactions);

export const {
    selectAll: selectFxOrderTransactions
} = fxOrderTransactionsAdapter.getSelectors((state: RootState) => state.start.fxOrderTransactions);
