import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { client } from '../../api/client'
import { RootState } from '../../app/store';
import { Transaction } from './Transaction';
import Config from '../../config/Config';
import { Pagination } from '../tech_messages/techMessagesSlice';
import { mapTransactions } from "../../services/utils";

const transactionsAdapter = createEntityAdapter<Transaction>({
    selectId: (transaction) => transaction.nrReferencyjny
});

const initialState = transactionsAdapter.getInitialState({
    status: '',
    entities: {},
    sort: '',
    shouldShowTransactionsBadge: false
});

export interface Sort {
    column: string;
    desc: boolean;
}

const defaultSortAndPagination = {
    column: 'nrReferencyjny',
    desc: true,
    pageNo: 1,
    pageSize: 15
};

export const fetchTransactions = createAsyncThunk('transactions/fetchTransactions',
    async (params: Sort & Pagination = defaultSortAndPagination) => {
        const response = await client.get(
            `${Config.dataUrl}/currentTransactions/listCurrentCustomerTransactions?` +
                `column=${params.column}&desc=${params.desc}&pageNo=${params.pageNo}&pageSize=${params.pageSize}`
        );
        return response.transactions;
    }
);

const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        transactionUpdated: transactionsAdapter.updateOne,
        showTransactionsBadge: (state) => {
            state.shouldShowTransactionsBadge = true;
        },
        hideTransactionsBadge: (state) => {
            state.shouldShowTransactionsBadge = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                transactionsAdapter.setAll(state, mapTransactions(action.payload));
                state.status = 'idle';
            });
    }
});

export const {
    transactionUpdated,
    showTransactionsBadge,
    hideTransactionsBadge
} = transactionSlice.actions;

export default transactionSlice.reducer;

export const {
    selectAll: selectTransactions
} = transactionsAdapter.getSelectors((state: RootState) => state.transactions);

export const selectShouldShowTransactionsBadge = (state: RootState) =>
    state.transactions.shouldShowTransactionsBadge;
