import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import { StyledButton } from '../common/controllers';
import { CommonDateControl, CommonSelectControl, CommonTextControl } from '../common/CommonFormControl';
import { store } from '../../app/store';
import { getCurrenciesForType } from '../exchange/transactionFormSlice';
import { dateToDateString } from '../../services/utils';
import { useEffect } from 'react';
import {
    clearFilter,
    exportExcel,
    fetchHistory,
    fetchTransactionStatuses,
    historyFilterDateChanged,
    historyFilterValueChanged
} from './historySlice';

export default function Filter() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        void dispatch(fetchTransactionStatuses());
        void dispatch(fetchHistory());
    }, [dispatch]);

    const emptyItem = <MenuItem key='' value=''>-</MenuItem>;

    const products = useAppSelector(state => state.history.filter.options.products)
        .map(item => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>);
    products.unshift(emptyItem);

    const statuses = useAppSelector(state => state.history.filter.options.statuses)
        .map(item => <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>);
    statuses.unshift(emptyItem);

    const currencies = useAppSelector(state => state.form.options.currencies)
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);
    currencies.unshift(emptyItem);

    const dateFrom = useAppSelector(state => state.history.filter.values.dateFrom);
    const dateTo = useAppSelector(state => state.history.filter.values.dateTo);

    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            gap: 1,
            gridTemplateRows: 'auto',
            gridTemplateAreas: `
                'date_from status product . . . .'
                'date_to currency amount . . . .'
                'submit clear export . . . .'
            `
        }}>
            <CommonDateControl
                name='date_from'
                value={dateFrom ? new Date(dateFrom) : null}
                callback={handleDateFromChange}
            />
            <CommonDateControl
                name='date_to'
                value={dateTo ? new Date(dateTo) : null}
                callback={handleDateToChange}
            />
            <CommonSelectControl
                name='status'
                items={statuses}
                callback={handleChange}
                value={useAppSelector(state => state.history.filter.values.status)}
            />
            <CommonSelectControl
                name='product'
                items={products}
                callback={handleTypeChange}
                value={useAppSelector(state => state.history.filter.values.product)}
            />
            <CommonSelectControl
                name='currency'
                items={currencies}
                callback={handleChange}
                value={useAppSelector(state => state.history.filter.values.currency)}
            />
            <CommonTextControl
                name='amount'
                callback={handleChange}
                value={useAppSelector(state => state.history.filter.values.amount)}
            />
            <StyledButton
                sx={{ gridArea: 'submit' }}
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleSubmit}
            >
                {t('filter')}
            </StyledButton>
            <StyledButton
                sx={{ gridArea: 'clear' }}
                fullWidth
                variant='outlined'
                color='primary'
                onClick={clearInputs}
            >
                {t('clear')}
            </StyledButton>
            <StyledButton
                sx={{ gridArea: 'export' }}
                fullWidth
                variant='outlined'
                color='primary'
                onClick={handleExport}
            >
                {t('excel_export')}
            </StyledButton>
        </Box>
    );
}

function handleChange(e: SelectChangeEvent) {
    store.dispatch(historyFilterValueChanged({ valType: e.target.name, value: e.target.value }));
}

function handleDateToChange(e: any) {
    store.dispatch(historyFilterDateChanged({ valType: 'dateTo', value: dateToDateString(e) }));
}

function handleDateFromChange(e: any) {
    store.dispatch(historyFilterDateChanged({ valType: 'dateFrom', value: dateToDateString(e) }));
}

function handleTypeChange(e: SelectChangeEvent) {
    handleChange(e);
    store.dispatch(getCurrenciesForType(Number(e.target.value)));
}

function handleSubmit() {
    store.dispatch(fetchHistory());
}

function clearInputs() {
    store.dispatch(clearFilter(null))
}

function handleExport() {
    store.dispatch(exportExcel());
}
