import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { StyledButton } from '../common/controllers';
import { CommonSelectControl, CommonTextControl, CommonTimeControl } from '../common/CommonFormControl';
import {
    handleAmountChange,
    handleChangeEvent,
    handleCurrencyChangeEvent, handleNumericValueChange,
    handlePickerChange,
    handleSubmit
} from "./tranactionForm";
import { ChangeEvent } from "react";

export default function FxOrderInputSet() {
    const { t } = useTranslation();

    const currencies = useAppSelector(state => state.form.options.currencies)
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);

    const sides = useAppSelector(state => state.form.options.sides)
        .map(item => <MenuItem key={item.key} value={item.key}>{t(item.value)}</MenuItem>);

    const endDates = useAppSelector(state => state.form.options.currencyDates)
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);

    const accounts1 = useAppSelector(state => state.form.options.accounts1)
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);

    const accounts2 = useAppSelector(state => state.form.options.accounts2)
        .map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);

    const submitAllowed = useAppSelector(state => state.form.options.submitAllowed);
    const productPrecision = useAppSelector(state => state.form.options.productPrecision);
    const validOfferTimes = useAppSelector(state => state.form.options.validOfferTimes);

    const currency = useAppSelector(state => state.form.values.currency);
    const side = useAppSelector(state => state.form.values.strona);
    const currencyDate = useAppSelector(state => state.form.values.currencyDate);
    const validTime = useAppSelector(state => state.form.values.validTime);
    const price = useAppSelector(state => state.form.values.price);
    const amount = useAppSelector(state => state.form.values.amount);
    const account1 = useAppSelector(state => state.form.values.account1);
    const account2 = useAppSelector(state => state.form.values.account2);
    const account1Curr = useAppSelector(state => state.form.options.account1Curr);
    const account2Curr = useAppSelector(state => state.form.options.account2Curr);

    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            gap: 1,
            gridTemplateRows: 'auto',
            gridTemplateAreas: `
                'currency strona currencyDate validTime . . .'
                'price amount account1 account2 . . .'
                'submit . . . . . .'
            `
        }}>
            <CommonSelectControl
                name='currency'
                items={currencies}
                callback={handleCurrencyChangeEvent}
                value={currency}
            />
            <CommonSelectControl
                name='strona'
                items={sides}
                callback={handleChangeEvent}
                value={side}
            />
            <CommonSelectControl
                name='currencyDate'
                items={endDates}
                callback={handleChangeEvent}
                value={currencyDate}
            />
            <CommonTimeControl
                name='validTime'
                callback={(value: Date) => handlePickerChange('validTime', value)}
                value={validTime}
                minTime={validOfferTimes?.minTime}
                maxTime={validOfferTimes?.maxTime}
            />
            <CommonTextControl
                required
                name='price'
                callback={(e) => handlePriceChange(e, productPrecision)}
                value={price}
            />
            <CommonTextControl
                required
                name='amount'
                callback={handleAmountChange}
                value={amount}
            />
            <CommonSelectControl
                translatedName={`${t('account')} ${account1Curr}`}
                name='account1'
                items={accounts1}
                callback={handleChangeEvent}
                value={account1}
            />
            <CommonSelectControl
                translatedName={`${t('account')} ${account2Curr}`}
                name='account2'
                items={accounts2}
                callback={handleChangeEvent}
                value={account2}
            />
            <StyledButton
                sx={{ gridArea: 'submit' }}
                type='submit'
                fullWidth
                variant='contained'
                onClick={handleSubmit}
                color='primary'
                disabled={!submitAllowed}
            >
                {t('send_transaction')}
            </StyledButton>
        </Box>
    );
}

function shouldUpdatePrice(precision: number | undefined, price: string) {
    return precision &&
        new RegExp(`^\\d{0,3}([.,]\\d{0,${precision}})?$`)
            .test(price);
}

function handlePriceChange(e: ChangeEvent<HTMLInputElement>, precision?: number) {
    if (shouldUpdatePrice(precision, e.target.value)) {
        handleNumericValueChange(e);
    }
}
