import { createTheme } from "@mui/material/styles";
import { purple } from "@mui/material/colors";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#008463'
        },
        secondary: {
            main: purple[500]
        }
    },
    typography: {
        fontSize: 14
    }
});