import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow, Typography
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { acceptTransaction, cancelTransaction, clearForm, openDialog, rejectTransaction } from './transactionFormSlice';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';
import { formatAmount, millisToDateString, millisToDateTimeString } from '../../services/utils';
import { useState } from "react";
import { CountdownRenderProps } from "react-countdown/dist/Countdown";
import { Product, Side, TransactionStatus } from "./TransactionForm";
import { changeTab } from "../nav/navSlice";
import { NavTab } from "../nav/navTypes";
import { Transaction } from "../transactions/Transaction";

export default function ConfirmWindow() {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const productId = useAppSelector(state => state.form.values.productId);
    const transaction = useAppSelector(state => state.form.currentTransaction);
    const serverTs = useAppSelector(state => state.form.serverTs);
    const isOpen = useAppSelector(state => state.form.dialogOpen);
    const isError = useAppSelector(state => state.form.isError);

    const errorMessages = useAppSelector(state => state.form.errorMessages)
        .map(((message, index) =>
            <Typography key={index} sx={{ color: 'red', marginTop: '1rem'}}>{message}</Typography>));

    const [isCountdownFinished, setIsCountdownFinished] = useState(false);

    const isOffer = transaction?.statusTransakcji === TransactionStatus.F;

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '90%' } }}
            maxWidth='xs'
            open={isOpen}
        >
            <DialogTitle>{t('quotation_assessment')}</DialogTitle>
            <DialogContent dividers>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 100 }} aria-label='simple table'>
                        <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component='th' scope='row'>{t('type')}</TableCell>
                                <TableCell align='right'>{transaction?.typTransakcji}</TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component='th' scope='row'>{t('ref_number')}</TableCell>
                                <TableCell align='right'>{transaction?.nrReferencyjny}</TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component='th' scope='row'>{t('status')}</TableCell>
                                <TableCell align='right' sx={isOffer ?
                                    { color: 'primary.main', textTransform: 'uppercase', fontWeight: 'bold' } : {}
                                }>
                                    {transaction?.statusTransakcjiOpis}
                                </TableCell>
                            </TableRow>
                            {!Product.isFx(productId) &&
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component='th' scope='row'>{t('term')}</TableCell>
                                    <TableCell align='right'>{transaction?.termin}</TableCell>
                                </TableRow>
                            }
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component='th' scope='row'>{t('amount')}</TableCell>
                                <TableCell align='right'>{formatAmount(transaction?.kwota)}</TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component='th' scope='row'>{t('currency')}</TableCell>
                                <TableCell align='right'>{transaction?.waluta}</TableCell>
                            </TableRow>
                            {Product.isFx(productId) &&
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component='th' scope='row'>{t('strona')}</TableCell>
                                    <TableCell align='right'>
                                        {t((transaction?.strona &&
                                            Side[transaction?.strona as keyof typeof Side]) as string)
                                        }
                                    </TableCell>
                                </TableRow>
                            }
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component='th' scope='row'>
                                    {t(Product.isFx(productId) ? 'start_date' : 'settlement_date')}
                                </TableCell>
                                <TableCell align='right'>{millisToDateString(transaction?.dataWaluty)}</TableCell>
                            </TableRow>
                            {!Product.isFx(productId) &&
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component='th' scope='row'>{t('end_date')}</TableCell>
                                    <TableCell align='right'>{millisToDateString(transaction?.dataZakonczenia)}</TableCell>
                                </TableRow>
                            }
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component='th' scope='row'>{t('price')}</TableCell>
                                <TableCell align='right' sx={isOffer ?
                                    { fontWeight: 'bold' } : {}
                                }>{formatAmount(transaction?.cenaOprocentowanie, 4)}</TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component='th' scope='row'>{t('offer_timeout')}</TableCell>
                                <TableCell align='right'>{
                                    transaction && isOffer && !isCountdownFinished && isOpen ?
                                        <Countdown
                                            date={transaction.czasWygasniecia}
                                            onComplete={handleCountdownFinished}
                                            renderer={renderer}
                                        /> :
                                        millisToDateTimeString(transaction?.czasWygasniecia)
                                }</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {isError &&
                    errorMessages
                }
            </DialogContent>
            <DialogActions>
                {isCancellationAvailable(transaction) &&
                    <Button onClick={handleCancellation}>{t('cancel_transaction')}</Button>
                }
                {transaction && isOffer && !isCountdownFinished && isOpen &&
                    <>
                        <Button onClick={handleAccept}>{t('accept')}</Button>
                        <Button autoFocus onClick={handleReject}>{t('reject')}</Button>
                    </>
                }
                <Button onClick={handleClose}>{t('close')}</Button>
            </DialogActions>
        </Dialog>
    );

    function renderer({ formatted: { minutes, seconds }}: CountdownRenderProps) {
        return (
            <Typography align='right' variant='body2' sx={{ fontWeight: 'bold' }}>
                {minutes}:{seconds}
            </Typography>
        );
    }

    function handleAccept() {
        void dispatch(acceptTransaction());
    }

    function handleReject() {
        void dispatch(rejectTransaction());
    }

    function handleClose() {
        setIsCountdownFinished(false);
        dispatch(openDialog(false));
        dispatch(clearForm());
        void dispatch(changeTab(NavTab.TRANSACTIONS));
    }

    function handleCountdownFinished() {
        setIsCountdownFinished(true);
    }

    function isCancellationAvailable(transaction?: Transaction) {
        return transaction && transaction?.produkt === Product.FX_ORDER &&
            TransactionStatus.isEligibleForCancellation(transaction?.statusTransakcji);
    }

    function handleCancellation() {
        void dispatch(cancelTransaction())
    }
}
