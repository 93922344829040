import Header from '../header/header';
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import { Box } from "@mui/material";

export interface HomeProps { onLogoutClick: () => void; }

export default function Home(props: Readonly<HomeProps>) {
    return (
        <Box sx={{
            boxSizing: 'border-box',
            width: '100vw',
            height: '100vh',
            display: 'grid',
            gridTemplateRows: 'repeat(22, 1fr)',
            padding: '5px'
        }}>
            <Header {...props} />
            <Nav/>
            <Box sx={{
                gridRow: '22',
                bgcolor: 'primary.main',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Footer/>
            </Box>
        </Box>
    );
}
