import { StyledFormControl } from './controllers';
import { InputLabel, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DesktopDatePicker, DesktopTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ChangeEvent } from 'react';
import pl from 'date-fns/locale/pl'

interface InputProps {
    name: string;
    label?: string;
    translatedName?: string;
}

interface SelectProps extends InputProps {
    items: JSX.Element[];
    value: string;
    callback: (e: SelectChangeEvent) => any;
}

interface TextProps extends InputProps {
    required?: boolean;
    value: string;
    callback: (e: ChangeEvent<HTMLInputElement>) => any;
    onBlurCallback?: any;
    inputProps?: any;
}

interface PickerProps extends InputProps {
    value: Date | null;
    callback: (e: any, keyboardInputValue?: string) => any;
}

interface DateProps extends PickerProps {
    minDate?: Date;
    maxDate?: Date;
    shouldDisableDate?: (day: Date) => boolean;
}

interface TimeProps extends PickerProps {
    minTime?: Date;
    maxTime?: Date;
}

export function CommonSelectControl(props: SelectProps) {
    const { t } = useTranslation();
    const label = props.translatedName ?? t(props.label ?? props.name);
    const labelId = `type${props.name}`;

    return (
        <StyledFormControl sx={{ gridArea: props.name }} variant='outlined'>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                name={props.name}
                onChange={props.callback}
                label={label}
                defaultValue=''
                value={props.value}
            >
                {props.items}
            </Select>
        </StyledFormControl>
    );
}

export function CommonTextControl(props: TextProps) {
    const { t } = useTranslation();
    const label = t(props.name);
    const labelId = `type${props.name}`;

    return (
        <StyledFormControl sx={{ gridArea: props.name }}>
            <InputLabel id={labelId}></InputLabel>
            <TextField
                required={props.required}
                name={props.name}
                onChange={props.callback}
                onBlur={props.onBlurCallback}
                label={label}
                value={props.value}
                inputProps={props.inputProps}
            />
        </StyledFormControl>
    );
}

export function CommonDateControl(props: DateProps) {
    const { t } = useTranslation();
    const label = t(props.name);

    return (
        <StyledFormControl sx={{ gridArea: props.name }} variant='outlined'>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
                <DesktopDatePicker
                    label={label}
                    value={props.value}
                    onChange={props.callback}
                    renderInput={params => <TextField {...params} />}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    shouldDisableDate={props.shouldDisableDate}
                />
            </LocalizationProvider>
        </StyledFormControl>
    );
}

export function CommonTimeControl(props: TimeProps) {
    const { t } = useTranslation();
    const label = t(props.name);

    return (
        <StyledFormControl sx={{ gridArea: props.name }} variant='outlined'>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
                <DesktopTimePicker
                    label={label}
                    value={props.value}
                    onChange={props.callback}
                    renderInput={params => <TextField {...params} />}
                    minTime={props.minTime}
                    maxTime={props.maxTime}
                />
            </LocalizationProvider>
        </StyledFormControl>
    );
}
