import { Alert, Button, Container, CssBaseline, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import logo from '../../img/logo-group@2x.png';
import { loginChanged, loginUser, passwordChanged, selectLogin, selectPassword, selectUser } from './loginSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from "react-router-dom";
import Config from "../../config/Config";
import BlankPage from "../../error/BlankPage";

const StyledSubmitBut = styled(Button)(({ theme }) => `
    margin: ${theme.spacing(3, 0, 2)}
`);

const StyledPaper = styled(Paper)(({ theme }) => `
    margin-top: ${theme.spacing(8)};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacing(6)};
`);


const StyledForm = styled('form')(({ theme }) => `
    width: 100%;
    margin-top: ${theme.spacing(1)}
`);

export default function Login() {
    const dispatch = useAppDispatch();
    const loginVal = useAppSelector(selectLogin);
    const passwordVal = useAppSelector(selectPassword);
    const showError = useAppSelector(state => state.login.error);
    const { t } = useTranslation();
    const user = useAppSelector(selectUser);

    if (!Config.loginViewEnabled) {
        return <BlankPage />;
    }

    if (user) {
        return <Navigate replace to='/' />;
    }

    return (
        <Container component='main' maxWidth='xs' style={{ marginTop: '10%' }}>
            <CssBaseline/>
            <StyledPaper>
                <img style={{ width: '80%'}} src={logo} alt='logo' />
                <Typography component='h1' variant='h5'></Typography>
                <Alert sx={{ display: showError ? 'flex' : 'none' }} severity='error'>
                    This is an error alert — check it out!
                </Alert>

                <StyledForm noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='login'
                        onChange={handleChanged}
                        label={t('login')}
                        name='login'
                        autoComplete='login'
                        value={loginVal}
                        autoFocus
                    />
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        onChange={handleChanged}
                        label={t('password')}
                        type='password'
                        id='password'
                        value={passwordVal}
                        autoComplete='current-password'
                    />
                    <StyledSubmitBut
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                    >
                        {t('sign_in')}
                    </StyledSubmitBut>
                </StyledForm>
            </StyledPaper>
        </Container>
    );

    function handleChanged(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        if (event.target.name === 'password') {
            dispatch(passwordChanged(event.target.value));
        } else {
            dispatch(loginChanged(event.target.value));
        }
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        void dispatch(loginUser());
    }
}
