import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { millisToDateTimeString } from '../../services/utils';
import { StyledTable } from '../transactions/transactionsList';
import { TechMessage } from './TechMessage';

export default function TechMessagesTable({ techMessages }: Readonly<{ techMessages: TechMessage[] }>) {
    const { t } = useTranslation();

    return (
        <TableContainer component={Paper}>
            <StyledTable size='medium' aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>{t('dispatch_time')}</TableCell>
                        <TableCell align='center'>{t('sender')}</TableCell>
                        <TableCell align='left'>{t('content')}</TableCell>
                        <TableCell align='center'>{t('validity_time')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {techMessages.map((message) => (
                        <TableRow key={message.komId}>
                            <TableCell align='center'>{millisToDateTimeString(message.czasNadania)}</TableCell>
                            <TableCell align='center'>{message.nadawca}</TableCell>
                            <TableCell align='left'>{message.tresc}</TableCell>
                            <TableCell align='center'>{millisToDateTimeString(message.czasWaznosci)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
    );
}
