import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TechMessageState, { newTechMessagesState } from './TechMessage';
import { client } from '../../api/client';
import Config from '../../config/Config';
import { RootState } from '../../app/store';
import { NavTab } from "../nav/navTypes";

const initialState: TechMessageState = newTechMessagesState();

export interface Pagination {
    pageNo: number;
    pageSize: number;
}

const defaultPagination = {
    pageNo: 1,
    pageSize: 15
};

export const getTechMessages = createAsyncThunk('techMessages/getTechMessages',
    async (params: Pagination = defaultPagination) => {
        const response = await client.get(
            `${Config.dataUrl}/messages/listTechMessages?pageNo=${params.pageNo}&pageSize=${params.pageSize}`
        );
        return response.techMessages;
    }
);

export function techMessageArrived(message: string) {
    return async (dispatch: any, getState: any) => {
        dispatch(showMessagesBadge());

        if (getState().nav.index === NavTab.NOTIFICATIONS) {
            dispatch(getTechMessages());
        }
    }
}

const techMessagesSlice = createSlice({
    name: 'techMessages',
    initialState,
    reducers: {
        showMessagesBadge: (state) => {
            state.shouldShowMessagesBadge = true;
        },
        hideMessagesBadge: (state) => {
            state.shouldShowMessagesBadge = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTechMessages.fulfilled, (state, action) => {
                state.techMessages = action.payload;
            });
    }
});

export const {
    showMessagesBadge,
    hideMessagesBadge
} = techMessagesSlice.actions;

export const selectTechMessages = (state: RootState) => state.techMessages;

export default techMessagesSlice.reducer;
