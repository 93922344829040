import { createAction, createSlice } from '@reduxjs/toolkit';
import { client } from '../../api/client';

import { fetchProductTypesForForm } from '../exchange/transactionFormSlice';
import { getCurrencyPairs } from '../currency_rates/currencyRatesSlice';
import { fetchProductTypesForFilter } from '../history/historySlice';

import { RootState } from '../../app/store';
import Config from '../../config/Config';
import User from "./User";
import { Product } from "../exchange/TransactionForm";
import { changeTab } from "../nav/navSlice";
import { NavTab } from "../nav/navTypes";

export interface LoginState {
    user: User | null;
    login: string | null;
    password: string | null;
    error: boolean;
}

const initialState: LoginState = {
    user: null,
    login: '',
    password: '',
    error: false
};

const userLoggedIn = createAction<User>('login/loginUser/success');
const userLoggedInFailed = createAction<string>('login/loginUser/failed');
export const clearStore = createAction('clearStore');

function dispatchSuccess(dispatch: any, user: User) {
    dispatch(userLoggedIn(user));
    dispatch(fetchProductTypesForFilter(user.clientProducts));
    dispatch(fetchProductTypesForForm(user.clientProducts));
    dispatch(getCurrencyPairs(Product.FX_SPOT));
    dispatch(changeTab(NavTab.START));
}

function dispatchFail(dispatch: any, error: any) {
    dispatch(userLoggedInFailed(error));
    dispatch(clearStore())
}

export function loginUser() {
    return async (dispatch: any, getState: any) => {
        const state = getState();
        try {
            const user: User = await client.post(`${Config.authUrl}/user`, {
                username: state.login.login,
                password: state.login.password
            });

            dispatchSuccess(dispatch, user);
        } catch (error: any) {
            dispatchFail(dispatch, error);
        }
    };
}

export function getCurrentUser() {
    return async (dispatch: any) => {
        try {
            const user: User = await client.get(`${Config.userUrl}/current`);

            dispatchSuccess(dispatch, user);
        } catch (error: any) {
            dispatchFail(dispatch, error);
        }
    };
}

export function logoutUser() {
    return async (dispatch: any) => {
        try {
            await client.post(Config.logoutUrl, {});
            dispatch(userLoggedOut());
        } catch (error: any) {
            dispatch(userLoggedOutFailed(error));
        } finally {
            dispatch(clearStore())

            if (!Config.loginViewEnabled) {
                window.location.href = Config.logoutRedirectUrl;
            }
        }
    };
}

export const userLoggedOut = createAction('login/logoutUser');
const userLoggedOutFailed = createAction<string>('login/logoutUserFailed');

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginChanged: (state, action) => {
            state.login = action.payload;
            state.error = false;
        },
        passwordChanged: (state, action) => {
            state.password = action.payload;
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userLoggedIn, (state, action) => {
                state.user = action.payload;
                state.user.generatedId = generateId();

                state.login = '';
                state.password = '';
            })
            .addCase(userLoggedInFailed, (state, action) => {
                clearState(state);
                sessionStorage.clear();
            })
            .addCase(userLoggedOut, (state, action) => {
                clearState(state);
                sessionStorage.clear();
            })
            .addCase(userLoggedOutFailed, (state, action) => {
                clearState(state);
                sessionStorage.clear();
            });
    }
});

function clearState(state: LoginState) {
    state.user = null;
    state.password = '';
    state.login = '';
    state.error = false;
}

function generateId() {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

    return (`${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`);
}

export const {
    loginChanged,
    passwordChanged
} = loginSlice.actions;

export const selectUser = (state: RootState) => state.login.user;
export const selectLogin = (state: RootState) => state.login.login;
export const selectPassword = (state: RootState) => state.login.password;

export default loginSlice.reducer;
