import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { downloadFile } from "../start/startSlice";
import { store } from "../../app/store";
import Config from "../../config/Config";

export default function Help() {
    const { t } = useTranslation();

    return (
        <Box>
            <Typography variant="body2" sx={{ color: 'text.primary', marginBottom: '2rem' }}>
                {t('help_manual_info')}
            </Typography>
            <Button
                variant='contained'
                color='primary'
                onClick={handleManualDownload}
            >
                {t('help_download_manual')}
            </Button>
        </Box>
    );
}

function handleManualDownload() {
    store.dispatch(downloadFile(Config.manualFileUrl));
}