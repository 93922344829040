const Config = {
    authUrl: `${window.CONFIG.apiUrl}/auth`,
    logoutUrl: `${window.CONFIG.apiUrl}/logout`,
    dataUrl: `${window.CONFIG.apiUrl}/data`,
    userUrl: `${window.CONFIG.apiUrl}/user`,
    websocketUrl: `${window.CONFIG.apiUrl}/websocket`,
    exchangeRatesTableUrl: window.CONFIG.exchangeRatesTableUrl,
    manualFileUrl: `${window.CONFIG.apiUrl}${window.CONFIG.manualFilePath}`,
    fxSpotFileUrl: `${window.CONFIG.apiUrl}${window.CONFIG.fxSpotFilePath}`,
    fxOrderFileUrl: `${window.CONFIG.apiUrl}${window.CONFIG.fxOrderFilePath}`,
    depositFileUrl: `${window.CONFIG.apiUrl}${window.CONFIG.depositFilePath}`,
    regulationsFileUrl: `${window.CONFIG.apiUrl}${window.CONFIG.regulationsFilePath}`,
    logoutTimeout: window.CONFIG.logoutTimeout,
    logoutRedirectUrl: window.CONFIG.logoutRedirectUrl,
    loginViewEnabled: window.CONFIG.loginViewEnabled,
}

export default Config;
