import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ErrorPageProps {
    error: Error;
    resetErrorBoundary: () => void;
}

export default function ErrorPage(props: ErrorPageProps) {
    const { t } = useTranslation();

    return (
        <Box sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Typography align='center' sx={{
                marginBottom: '1rem',
                fontSize: '1.2rem'
            }}>{t('something_went_wrong')}</Typography>
            {props.resetErrorBoundary &&
                <Button onClick={props.resetErrorBoundary}>{t('back')}</Button>
            }
        </Box>
    );
}